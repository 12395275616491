<template>
    <div v-if="isDropdown" :class="classes + ' ' + 'dropdown'">
        <ul>
            <li @click="close"  class="dropdown__item" v-for="item of list" :key="item.link">  <RouterLink :to="item.link">{{ item.text }}</RouterLink></li>
        </ul>
    </div>  
</template>
<script>
import './dropdown.css'
export default{
    name: 'DropDownList',
    props: [
        'list',
        'isDropdown',
        'classes'
    ],
    methods:{
        close(){
            this.$emit("updateParent", { isDropdown: false });
        }
    }
}
</script>