<template>
  <div class="price-item">
    <div :class="'price-item__header' + ' ' + izd">{{ header }}</div>
    <img
      :src="img"
      :alt="header"
      class="price-item__img"
    />
    <div class="price-item__content">
      <span v-if="oldprice" class="price-item__price price-item__price_oldprice">от {{ oldprice }} руб.</span>
      <span class="price-item__price">от {{ price }} руб.</span>
      <!-- <span class="price-item__text">размер: {{ size }} см.</span> -->
      <span class="price-item__text">материал: {{ material }}</span>
      <button :class="'price-item__btn' + ' ' + izd" @click="isModal = true">Заказать</button>
      <img
        :src="require(`@/assets/images/price-card/${izd}/vector_1.png`)"
        class="price-item__vector"
      />
    </div>

    <ModalComponent v-if="isModal" @updateParent="isModal = $event.isModal">
      <FormComponent
        @closeModal="isModal = $event.isModal"
        :title="'Заказ' + ' ' + header"
        :izd="izd"
        :clientMail= "['frontprstvasya@gmail.com']"
        :izdInfo="{ izdName: header, izdMaterial: material, izdSize: size }"
        :type="'order'"
      />
    </ModalComponent>
  </div>
</template>

<script>
import FormComponent from "../../Form/FormComponent.vue";
import "./price-item.css";
import ModalComponent from "@/components/UI/Modal/ModalComponent.vue";
export default {
  name: "PriceItem",
  props: ["header", "img", "price", "size", "material", "izd", "oldprice"],
  data() {
    return {
      isModal: false,
    };
  },
  components: { ModalComponent, FormComponent },
};
</script>
