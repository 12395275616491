<template>
    <section>
        <wrapper-component :id="'advantages'">
            <TitleComponent :title="title" :customClass="'advantages__title'"/>
            <div class="advantages__content">
                <AdvantageItem v-for="(item, index) in list" :key="index" :img="item.img" :text="item.text"/>
            </div>
        </wrapper-component>
    </section>
</template>

<script>
import './advantages.css';
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import AdvantageItem from "./AdvantageItem/AdvantageItem.vue";
import TitleComponent from '@/components/UI/Title/TitleComponent.vue';
    export default{
        name: 'AdvantagesComponent',
        props: ['sectionName', 'title'],
        components: {WrapperComponent, AdvantageItem, TitleComponent},
        data(){
            return{
              list: [
                {text: '25+ лет успешной работы', img: '1.jpg'},
                {text: '2000 довольных клиентов', img: '2.jpg'},
                {text: '85 000+ отгруженных изделий', img: '3.jpg'},
                {text: '23 типа солнцезащитных систем', img: '4.jpg'},
                {text: '400 + материалов', img: '5.jpg'},
                {text: '12 месяцев гарантии', img: '6.jpg'},
              ]     
            }
        }
    }
</script>

<style>
</style>