<template>
    <div class="burger">
        <button class="burger__button" @click="isDropdown = !isDropdown">
          <span class="burger__lines"></span>
          <span class="burger__lines"></span>
          <span class="burger__lines"></span>
        </button>
        <DropDownListVue @updateParent="isDropdown = $event.isDropdown" :isDropdown="isDropdown" :list="list" :classes="'burger__dropdown'"/>
    </div>
</template>

<script>
import './burger.css'
import DropDownListVue from '@/components/UI/DropDownList/DropDownList.vue'
export default{
    name: 'BurgerComponent',
    components: {DropDownListVue},
    props:['list'],
    data(){
        return{
            isDropdown: false,
        }
    }
}
</script>