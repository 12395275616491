<template>
  <wrapper-component :id="'aboutUs'">
    <TitleComponent :title="title" />
    <p class="standart-text" v-for="(item, index) of textList" :key="index">{{ item }}</p>
    <div class="our-command">
      <h2 class="our-command__title">Наша команда</h2>
      <ul class="our-command__list">
        <li class="our-command__item" v-for="(item, index) of commandList" :key="index">
          <img
            :src="require(`@/assets/images/command/${item.img}`)"
            class="our-command__img"
            :alt="'command_' + (index + 1)"
          />
          <span class="our-command__name">{{ item.name }}</span>
          <span class="our-command__subtitle bold standart-text">{{ item.subtitle }}</span>
          <span class="our-command__description standart-text">{{item.description}}</span>
        </li>
      </ul>
    </div>
  </wrapper-component>
</template>

<script>
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
import "./about-us.css";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
export default {
  name: "AboutUsComponent",
  components: { WrapperComponent, TitleComponent },
  props: ["title"],
  data() {
    return {
      textList: [
        `Салон «СБ Декор» работает в Рязани с 1997 года. Вот уже более 20 лет мы создаём уют в ваших домах, квартирах и офисах. Имеем собственное производство по пошиву штор, других текстильных и солнцезащитных изделий для дома и офиса. Высококвалифицированные дизайнеры готовы принять заказ по телефону, сориентировать Вас в огромном разнообразии тканей для штор и солнцезащитных систем, пройти с Вами весь путь от эскиза до готового изделия.`,
        `Мы начинали работу с частными клиентами, затем накопленный опыт работы позволил нам начать активное сотрудничество и с корпоративными заказчиками, а затем создать и развить дилерскую сеть.`,
        `С 2015 года салон штор и жалюзи «СБ Декор» стал официальным представителем торговой марки солнцезащитных систем FOROOM в Рязанской области.`,
      ],
      commandList: [
        {
          img: "zhilyakov.jpg",
          description: `Знает всё о жалюзи, рулонных и римских шторах, шторах плиссе торговой марки FOROOM. Окажет любую техническую консультацию.`,
          name: "Владислав Жиляков",
          subtitle: 'Старший менеджер'
        },
        {
          img: "altinov.png",
          description: "Расскажет о солнцезащитных системах FOROOM, поможет с выбором и рассчитает стоимость.",
          name: "Александр Алтынов",
          subtitle: 'Руководитель салона «СБ Декор»'
        },
      ],
    };
  },
};
</script>
