<template>
  <section class="reviews">
    <wrapper-component :id="'reviews'">
      <TitleComponent :title="title" :customClass="'reviews__title'" />
      <iframe src="https://yandex.ru/maps-reviews-widget/1037798507?comments"></iframe>
  </wrapper-component>
  </section>
</template>
<script>

import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./reviews.css";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";

export default {
  name: "HeroSliderComponent",
  props: ["title"],
  components: {
    WrapperComponent,
    TitleComponent,
  },
  computed: {
    fullTablet() {
      return this.$store.getters.fullTablet;
    },
    fullDesktop() {
      return this.$store.getters.fullDesktop;
    },
  },
};
</script>
